<template>
  <div id="all-invoices">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container fluid>
      <h1>Invoices</h1>
      <b-form-group class="mb-1" @keydown.native.enter="search" @keydown.native.esc="clearQuery">
        <b-input-group>
          <b-form-input v-model="query" placeholder="Type to Search" />
          <b-input-group-append>
            <b-btn variant="primary" :disabled="!query" @click="search">OK</b-btn>
            <b-btn :disabled="!query" @click="clearQuery">Clear</b-btn>
            <b-btn
              variant="outline-secondary"
              target="_blank"
              href="https://reducr.atlassian.net/wiki/spaces/OP/pages/776732673/Invoice+search"
              >Tips</b-btn
            >
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div class="text-right" v-if="pager.totalCount >= 0">
        <small class="text-muted">{{ pager.totalCount }} Invoices found in {{ queryTime }} ms</small
        >.
      </div>
      <div v-if="amountStats">
        <div v-if="amountStats.count">
          <p>
            Amount Summary:
            <label>Count:</label>
            {{ amountStats.count }},
            <label>Max:</label>
            {{ amountStats.max | currency }},
            <label>Mean:</label>
            {{ amountStats.mean | currency }},
            <label>Sum:</label>
            {{ amountStats.sum | currency }}
          </p>
        </div>
      </div>
      <b-row>
        <b-col
          class="p-0"
          v-if="visibleBuckets && visibleBuckets.length > 0"
          sm="3"
          order="1"
          order-sm="2"
        >
          <ul id="facets">
            <li
              v-if="
                organisationBuckets &&
                organisationBuckets.length > 0 &&
                customerBuckets &&
                customerBuckets.length > 0
              "
              class="mb-2"
            >
              <b-select
                :options="[
                  { text: 'Organisations', value: 'org' },
                  { text: 'Suppliers', value: 'cust' }
                ]"
                v-model="selectedGroup"
              />
            </li>
            <li>
              <h4>
                Groups
                <b-btn
                  v-if="visibleBuckets && visibleBuckets.length > maxBuckets"
                  id="showHideAll"
                  variant="outline-secondary"
                  @click.stop="showAllBuckets = !showAllBuckets"
                  class="float-right"
                  >Show {{ showAllBuckets ? maxBuckets : visibleBuckets.length }}</b-btn
                >
              </h4>
            </li>
            <li v-for="item in buckets()" :key="item.value">
              <b-badge class="mr-1" variant="secondary">{{ item.count }}</b-badge>
              <b-link
                v-if="visibleBuckets.length > 1"
                @click.stop="selectFromBucket(item.value, selectedGroup)"
                >{{ orgShortName(item.value) }}</b-link
              >
              <span v-else>{{ item.value }}</span>
            </li>
            <li v-if="previousQuery">
              <b-btn
                class="mt-2"
                variant="outline-secondary"
                @click.stop="
                  query = previousQuery;
                  previousQuery = null;
                  search();
                "
                >Clear Grouping</b-btn
              >
            </li>
          </ul>
        </b-col>
        <div></div>
        <b-col sm="9" order="2" order-sm="1">
          <div class="d-flex">
            <b-select
              v-model="pager.count"
              :options="perPageOptions"
              class="reducer-select small-select ml-auto mr-3"
              @input="fetchData"
            />
            <b-pagination
              :total-rows="pager.totalCount"
              :per-page="pager.count"
              v-model="pager.current"
              :first-text="'\u00AB'"
              :prev-text="'\u2039'"
              :next-text="'\u203A'"
              :last-text="'\u00BB'"
              :ellipsis-text="'\u2026'"
              align="right"
            />
          </div>
          <b-table
            outlined
            ref="invoices"
            :empty-text="emptyText"
            show-empty
            responsive
            :current-page="pager.current"
            :items="invoiceProvider"
            :fields="fields"
            empty-filtered-text="No invoices matching filter."
            :per-page="pager.count"
          >
            <template v-slot:cell(tabs)="row">
              <b-dropdown text="Actions" lazy>
                <organisation-tabs
                  class="ml-1"
                  :organisationId="row.item.organisationId"
                  text="Actions"
                />
              </b-dropdown>
            </template>

            <template v-slot:cell(subAmount)="row">{{ row.value | currency }}</template>
            <template v-slot:cell(taxAmount)="row">{{ row.value | currency }}</template>
            <template v-slot:cell(amount)="row">{{ row.value | currency }}</template>

            <template v-slot:cell(spotlightGroup)="row">
              <SpotlightIcon :sector="row.value" />
            </template>

            <template #head(attachments)="row">
              <font-awesome-icon
                class="text-muted"
                :icon="['fas', 'paperclip']"
                :title="row.label"
              />
            </template>
            <template v-slot:cell(attachments)="row">
              <AttachmentButton :row="row" />
            </template>
            <template v-slot:cell(createdDate)="row">
              {{ row.value | date }}
            </template>
            <template v-slot:cell(createdInReducer)="row">
              {{ row.value | date }}
            </template>
            <template v-slot:cell(dueDate)="row">
              {{ row.value | date }}
            </template>
            <template v-slot:cell(lineItemDescriptions)="row">
              <span v-html="row.value" />
            </template>
            <template v-slot:cell(organisationName)="row">
              {{ orgShortName(row.value) }}
            </template>

            <template v-slot:cell(actions)="row">
              <b-button size="sm" @click.stop="row.toggleDetails">
                Lines
                <b-badge
                  variant="primary"
                  v-if="row.item.invoiceLineItems && row.item.invoiceLineItems.length > 1"
                  >{{ row.item.invoiceLineItems.length }}</b-badge
                >
              </b-button>
            </template>
            <template v-slot:row-details="row">
              <ul>
                <li v-for="(value, key) in row.item.invoiceLineItems" :key="key">
                  <span v-for="(ivalue, ikey) in value" :key="ikey">
                    <span
                      v-if="
                        ikey != 'invoiceLineItemId' &&
                        ikey != 'organisationId' &&
                        ikey != 'invoiceId' &&
                        ivalue != null
                      "
                    >
                      <strong>{{ ikey }}:</strong>
                      {{ ivalue }}
                    </span>
                  </span>
                </li>
              </ul>
            </template>
          </b-table>
          <b-pagination
            :total-rows="pager.totalCount"
            :per-page="pager.count"
            v-model="pager.current"
            :first-text="'\u00AB'"
            :prev-text="'\u2039'"
            :next-text="'\u203A'"
            :last-text="'\u00BB'"
            :ellipsis-text="'\u2026'"
            align="right"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#all-invoices label {
  font-weight: bold;
}
#facets {
  list-style-type: none;
  padding: 10px;
  border: 2px solid $color-font-para;
  border-radius: 10px;
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import AttachmentButton from "@/components/AttachmentButton";
import SpotlightIcon from "@/components/spotlight/SpotlightIcon";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import OrganisationTabs from "@/components/nav/OrganisationTabs";
import FormatHelper from "@/helper/formathelper";

export default {
  name: "all-invoices",
  components: {
    AttachmentButton,
    SpotlightIcon,
    OrganisationTabs
  },
  computed: {
    visibleBuckets: function () {
      if (this.selectedGroup == "org") {
        return this.organisationBuckets;
      } else if (this.selectedGroup == "cust") {
        return this.customerBuckets;
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      title: "Invoices",
      defaultEmptyText: "Enter a query to search invoices",
      emptyText: this.defaultEmptyText,
      invoices: [],
      fields: [
        {
          key: "tabs",
          label: "",
          class: "text-center"
        },
        {
          key: "attachments",
          class: "text-center"
        },
        {
          key: "spotlightGroup",
          label: "",
          class: "text-center align-middle"
        },
        { key: "organisationName", label: "Organisation" },
        { key: "customerName", label: "Supplier" },
        { key: "taxAmount", label: "Tax" },
        { key: "amount", label: "Amount inc tax" },
        { key: "createdDate", label: "Issued" },
        { key: "createdInReducer", label: "Added to Reducer" },
        { key: "invoiceReference", label: "Invoice Number" },
        { key: "lineItemDescriptions", label: "Details" },
        { key: "actions", label: "" }
      ],
      sortBy: null,
      sortDesc: null,
      perPageOptions: [
        { value: 20, text: "20 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" }
      ],
      pager: {
        start: 0,
        count: 20,
        current: 1,
        totalCount: null
      },
      query: null,
      queryTime: null,
      previousQuery: null,
      amountStats: null,
      organisationBuckets: [],
      customerBuckets: [],
      showAllBuckets: false,
      maxBuckets: 100,
      selectedGroup: "org"
    };
  },
  methods: {
    orgShortName: FormatHelper.orgShortName,
    invoiceProvider(ctx, callback) {
      this.invoices = [];

      if (ctx.currentPage > 0) {
        this.pager.start = (ctx.currentPage - 1) * this.pager.count;

        this.fetchData().then(() => {
          callback(this.invoices);
        });
      } else {
        callback([]);
      }

      return null;
    },
    async fetchData() {
      this.emptyText = "Loading...";
      const client = await ApiHelper.http();

      try {
        const response = await client.get(
          `${ApiHelper.endPoint()}invoices${this.getInvoicesParams()}`
        );

        Console.log(response);

        if (response.data.status === "success") {
          this.emptyText = "No invoices found";
          this.invoices = response.data.invoices;
          this.amountStats = response.data.amountStats;

          this.organisationBuckets = response.data.organisationBuckets;
          this.customerBuckets = response.data.customerBuckets;

          if (this.organisationBuckets && this.organisationBuckets.length > 0) {
            this.selectedGroup = "org";
          } else if (this.customerBuckets && this.customerBuckets.length > 0) {
            this.selectedGroup = "cust";
          }

          this.pager.totalCount = response.data.pager.totalCount;
          this.queryTime = response.data.queryTime;

          response.data.spotlightGroups.forEach((group) => {
            if (group) {
              group.invoices.forEach((spotlightInvoice) => {
                let foundIndex = this.invoices.findIndex(
                  (invoice) => invoice.invoiceId === spotlightInvoice.invoiceId
                );
                if (foundIndex !== -1) {
                  this.invoices[foundIndex].spotlightGroup = group.name;
                }
              });
            }
          });
        } else {
          this.showWarning((this.emptyText = "There was a problem getting invoices"));
        }
      } catch (err) {
        Console.error(err);
        this.showWarning((this.emptyText = "There was a problem getting invoices"));
      }
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    clearQuery() {
      this.query = "";
      this.queryTime = null;
      this.clearData();
      this.$refs.invoices.refresh();
    },
    getInvoicesParams() {
      let params = ApiHelper.appendParam(null, "offset", this.pager.start);
      params = ApiHelper.appendParam(params, "itemsPerPage", this.pager.count);
      params = ApiHelper.appendParam(params, "sortBy", this.pager.sortBy);
      params = ApiHelper.appendParam(params, "includeSpotlight", true);
      params = ApiHelper.appendParam(params, "summariseSpotlight", true);
      params = ApiHelper.appendParam(params, "sortDirection", this.pager.sortDirection);

      params = ApiHelper.appendParam(
        params,
        "query",
        this.query ? encodeURIComponent(this.query) : "*"
      );

      return params;
    },
    clearData() {
      this.pager.totalCount = null;
      this.pager.start = 0;
      this.pager.current = 1;
      this.emptyText = this.defaultEmptyText;
      this.invoices = [];
      this.$refs.invoices.refresh();

      if (this.previousQuery && (!this.query || !this.query.startsWith(this.previousQuery))) {
        this.previousQuery = null;
      }

      this.amountStats = null;
      this.organisationBuckets = [];
      this.customerBuckets = [];
    },
    async search() {
      this.clearData();
      this.$refs.invoices.refresh();
    },
    selectFromBucket(name, group) {
      if (this.query) {
        this.previousQuery = this.query;
        if (group == "org") {
          this.query = this.query + ' AND literalorganisationname:"' + name + '"';
        } else if (group == "cust") {
          this.query = this.query + ' AND literalcustomername:"' + name + '"';
        }
      } else {
        if (group == "org") {
          this.query = 'literalorganisationname:"' + name + '"';
        } else if (group == "cust") {
          this.query = 'literalcustomername:"' + name + '"';
        }
      }

      this.search();
    },
    buckets() {
      return this.showAllBuckets || this.visibleBuckets <= this.maxBuckets
        ? this.visibleBuckets
        : this.visibleBuckets.slice(0, this.maxBuckets);
    }
  }
};
</script>


